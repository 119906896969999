<template>
  <div>
    <data-list
      :headers="listHeaders"
      :payload="listPayload"
      class="ma-2"
    ></data-list>
  </div>
</template>
<script>
export default {
  data () {
    return {
      listPayload: {
        items: [],
        showSelect: true,
        actionsList: [{
          click: this.deleteHandler,
          icon: 'mdi-delete',
          color: 'error'
        }]
      }
    }
  },
  components: {
    'data-list': () => import(/* webpackChunkName: "data-list" */ '@/components/DataList.vue')
  },
  computed: {
    listHeaders () {
      return [{
        text: 'Name',
        value: 'name'
      }, {
        text: 'Phone',
        value: 'phone'
      }, {
        text: 'Message',
        value: 'message'
      }, {
        text: 'Sent at',
        value: 'updatedAt'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  beforeMount () {
    this.getAllUserQueries()
  },
  methods: {
    async getAllUserQueries () {
      const { data } = await this.$_execute('get', 'user-queries') || {}
      this.listPayload.items = data.map((item) => ({ ...item, updatedAt: this.$_formatDate(item.updatedAt) }))
    },
    deleteHandler ({ _id: itemid } = {}) {
      this.$_execute('delete', `user-queries/${itemid}`)
        .then(() => {
          this.$root.$emit('snackbar', { color: 'success', text: 'Subscription email deleted successfully' })
          this.getAllUserQueries()
        })
    }
  }
}
</script>
